<template>
  <div>
    <!-- 联赛 -->
    <el-form
      v-loading="loading"
      label-width="240px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
      <!-- 赛事名称 -->
      <el-form-item
        label="赛事名称"
        prop="match_name"
        label-width="240px"
        style="margin-bottom: 35px"
      >
        <!-- 编辑时不能改 -->
        <el-col :span="6">
          <el-select
            v-model="dataForm.match_type"
            value-key="typeId"
            style="width: 200px"
            :disabled="dataForm._id != undefined"
            @change="getClearMatchNames"
          >
            <el-option
              v-for="(value, key) in optionsMatch"
              :key="key"
              :label="value"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="比赛时间" required label-width="240px">
        <el-col :span="5">
          <el-form-item prop="startTime" id="startTime">
            <el-date-picker
              ref="startTime"
              type="datetime"
              placeholder="选择比赛开始时间"
              v-model="dataForm.startTime"
              style="width: 100%"
              :default-time="selectable"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="endTime">
          <el-form-item prop="endTime">
            <el-date-picker
              ref="endTime"
              type="datetime"
              placeholder="选择比赛结束时间"
              v-model="dataForm.endTime"
              style="width: 100%"
              :default-time="endSelectable"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <!-- 赛事总奖金额 -->
      <!-- <el-form-item label="赛事总奖金额" prop="prize" id="prize">
        <el-input
          v-model="dataForm.prize"
          placeholder="请输入内容"
          class="TotalAmount"
        >
          <template slot="append">元</template>
        </el-input>
      </el-form-item> -->
      <!-- KV图 -->
      <el-form-item
        label="KV图"
        label-width="240px"
        prop="kvPicture"
        id="kvPicture"
      >
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeTopImageUpload"
          action="#"
          drag
          :http-request="httpUploadRequest"
        >
          <img
            v-if="dataForm.kvPicture"
            :src="dataForm.kvPicture"
            class="avatar"
          />
          <div v-else>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
          </div>
        </el-upload>
      </el-form-item>

      <el-form-item
        v-if="dataForm.match_type == 'match_s1'"
        id="linkList.officialDirect_linkH5"
        label-width="240px"
        label="门派联赛官方直邀链接H5"
        prop="linkList.officialDirect_linkH5"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.linkList.officialDirect_linkH5"
            placeholder="请输入门派联赛官方直邀链接H5"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        v-if="dataForm.match_type == 'match_s1'"
        id="linkList.officialDirect_link"
        label-width="240px"
        label="门派联赛官方直邀链接小程序"
        prop="linkList.officialDirect_link"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.linkList.officialDirect_link"
            placeholder="请输入门派联赛官方直邀链接小程序"
          ></el-input>
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="dataForm.match_type == 'match_wdl'"
        id="linkList.audition_link"
        label-width="240px"
        label="第四届城市联赛海选赛链接H5"
        prop="linkList.audition_linkH5"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.linkList.audition_linkH5"
            placeholder="请输入第四届城市联赛海选赛链接H5"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        v-if="dataForm.match_type == 'match_wdl'"
        id="linkList.audition_link"
        label-width="240px"
        label="第四届城市联赛海选赛链接小程序"
        prop="linkList.audition_link"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.linkList.audition_link"
            placeholder="请输入第四届城市联赛海选赛链接小程序"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        v-if="dataForm.match_type == 'match_wdl'"
        id="linkList.authorizedTourn_linkH5"
        label-width="240px"
        label="第四届城市联赛授权赛链接H5"
        prop="linkList.authorizedTourn_linkH5"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.linkList.authorizedTourn_linkH5"
            placeholder="请输入第四届城市联赛授权赛链接H5"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        v-if="dataForm.match_type == 'match_wdl'"
        id="linkList.authorizedTourn_link"
        label-width="240px"
        label="第四届城市联赛授权赛链接小程序"
        prop="linkList.authorizedTourn_link"
        style="margin-bottom: 35px"
      >
        <el-col :span="6">
          <el-input
            ref="ext_attestation"
            v-model="dataForm.linkList.authorizedTourn_link"
            placeholder="请输入第四届城市联赛授权赛链接小程序"
          ></el-input>
        </el-col>
      </el-form-item>
      <!-- 门派奖励 -->
      <el-form-item
        label="比赛奖励"
        label-width="240px"
        prop="teamReward.reward"
        id="quillEditorQiniu"
      >
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>

        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.teamReward.reward"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>
      <!-- 积分规则 -->
      <el-form-item
        label="规则"
        label-width="240px"
        prop="scoreRule.rule"
        id="scoreEditorQiniuPrize"
      >
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0"
          :http-request="httpUploadEditorRequest1"
        >
        </el-upload>
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.scoreRule.rule"
          ref="customQuillEditorPrize"
          :options="editorOptionPrize"
        >
        </quill-editor>
      </el-form-item>
      <!-- 个人奖励 -->
      <el-form-item
        v-if="
          ['match_s2', 'match_s1', 'match_s3'].includes(matchType) ||
          ['match_s2', 'match_s1', 'match_s3'].includes(dataForm.match_type)
        "
        label="个人奖励"
        label-width="240px"
        prop="personReword.reward"
        id="quillEditorQiniugeren"
      >
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0"
          :http-request="httpUploadEditorRequest2"
        >
        </el-upload>

        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.personReword.reward"
          ref="customQuillEditormy"
          :options="editorOptiongeren"
        >
        </quill-editor>
      </el-form-item>
      <!-- 提交/取消 -->
      <el-form-item style="margin-top: 50px">
        <perButton
          type="primary"
          perm="armory:reporter:save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <el-button size="large" @click="goBack()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { toolbarOptions } from "./public.js/toolbarOptions";
import { mapActions, mapState } from "vuex";
export default {
  name: "managertwo",
  components: {
    perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
    isEdit: function () {
      if (this.view_type == "edit") {
        return true;
      }

      return false;
    },
  },
  data() {
    let self = this;
    const matchEndTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.startTime != null && self.dataForm.endTime != null) {
        let startTime = new Date(self.dataForm.startTime);
        let endTime = new Date(self.dataForm.endTime);
        if (endTime <= startTime) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    return {
      selectable: "05:00:00 ",
      endSelectable: "04:59:59",
      matchType: "", //获取路由跳来的值
      match_data: [],
      optionsMatch: [],
      view_type: "edit",
      type: "s1",
      match_names: [],
      org_myjl: "",
      org_rule: "",
      org_prize: "",
      dataForm: {
        _id: "",
        match_type: "",
        startTime: "",
        endTime: "",
        prize: "",
        linkList: {
          audition_linkH5: "",
          audition_link: "",
          officialDirect_link: "",
          authorizedTourn_link: "",
          officialDirect_linkH5: "",
          authorizedTourn_linkH5: "",
        },
        //个人奖励
        personReword: {
          reward: "",
          reward_url: "",
        },
        // 队伍奖励
        teamReward: {
          reward: "",
          reward_url: "",
        },
        // 规则
        scoreRule: {
          rule: "",
          rule_url: "",
        },
        kvPicture: "",
      },
      rules: {
        kvPicture: [
          {
            required: true,
            message: "请选择KV图",
            trigger: ["blur", "change"],
          },
        ],
        // "linkList.officialDirect_link": [
        //   {
        //     required: true,
        //     message: "请输入链接",
        //     trigger: ["blur"],
        //   },
        // ],
        // "linkList.authorizedTourn_link": [
        //   {
        //     required: true,
        //     message: "请输入链接",
        //     trigger: ["blur"],
        //   },
        // ],
        // "linkList.audition_link": [
        //   {
        //     required: true,
        //     message: "请输入链接",
        //     trigger: ["blur"],
        //   },
        // ],
        prize: [
          {
            required: true,
            message: "请输入赛事总奖金额",
            trigger: ["change", "blur"],
          },
        ],
        startTime: [
          {
            type: "date",
            required: true,
            message: "请选择比赛开始日期",
            trigger: ["change", "blur"],
          },
        ],
        endTime: [
          {
            type: "date",
            required: true,
            message: "请选择比赛结束日期",
            trigger: ["change"],
          },
          { validator: matchEndTimeCheckRule, trigger: ["change"] },
        ],
        "personReword.reward": [
          {
            required: true,
            message: "请输入个人奖励",
            trigger: ["blur", "change"],
          },
        ],
        "teamReward.reward": [
          {
            required: true,
            message: "请输入门派奖励",
            trigger: ["blur", "change"],
          },
        ],

        "scoreRule.rule": [
          {
            required: true,
            message: "请输入积分规则",
            trigger: ["blur", "change"],
          },
        ],
      },
      editorOptiongeren: {
        placeholder: "请填写个人奖励",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function (value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function (value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniugeren .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniugeren .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      editorOption: {
        placeholder: "请填写赛事规则",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function (value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function (value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function (value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      editorOptionPrize: {
        placeholder: "请填写赛事奖励",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function (value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function (value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#scoreEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#scoreEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      loading: false,
    };
  },
  watch: {
    // $route: {
    //   handler: function(val) {
    //     if (val.query.view_type == "add") {
    //       this.initData();
    //     }
    //   },
    //   immediate: true,
    // },
    match_data: {
      handler: function (val) {
        this.setFormData();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.matchType = this.$route.query.match_type;
    console.log(this.matchType);
    this.dataForm._id = this.$route.query._id;
    this.dataForm.match_type = this.$route.query.match_type;
    //编辑
    if (this.dataForm._id != undefined) {
      let res = await this.$http.match_subject.match_detail(this.dataForm._id);
      if (res.code == 0) {
        this.match_data = res.data;
      }
    }

    // this.setFormData();
    this.getMatchNames();
  },
  methods: {
    getClearMatchNames() {
      this.dataForm.linkList = {
        audition_link: "",
        officialDirect_link: "",
        authorizedTourn_link: "",
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    async getMatchNames() {
      const { data } = await this.$http.match_subject.match_list_names();
      this.optionsMatch = data;
    },

    getDateTime(dataStr) {
      if (dataStr != null) {
        return new Date(dataStr);
      }

      return null;
    },
    async setFormData() {
      // this.view_type = this.$route.query.view_type;
      //如果没拿到数据。则代表为新建
      if (
        JSON.stringify(this.match_data) != "{}" &&
        JSON.stringify(this.match_data) != undefined
      ) {
        if (this.match_data.length != 0) {
          let data = this.match_data;
          Object.keys(this.dataForm).forEach((key) => {
            if (data[key]) {
              this.dataForm[key] = data[key];
            }
          });
          this.dataForm.startTime = this.getDateTime(
            this.match_data.startTime || null
          );
          this.dataForm.endTime = this.getDateTime(
            this.match_data.endTime || null
          );
          this.dataForm.match_type = this.match_data.match_type;
          this.org_rule = this.match_data.teamReward.reward;
          this.org_myjl = this.match_data.personReword.reward;
          this.org_score = this.match_data.scoreRule.rule;
        }
      } else {
        this.initData();
      }
    },
    initData() {
      this.dataForm = {
        _id: "",
        match_type: "",
        startTime: "",
        endTime: "",
        linkList: {
          audition_link: "",
          officialDirect_link: "",
          authorizedTourn_link: "",
        },
        personReword: {
          reward: "",
          reward_url: "",
        },
        teamReward: {
          reward: "",
          reward_url: "",
        },
        scoreRule: {
          rule: "",
          rule_url: "",
        },
        kvPicture: "",
      };
    },
    //返回上一次
    goBack() {
      this.$router.push(`/match/subject/match_list`);
    },
    // 提交
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          //  个人奖励
          if (this.org_myjl != this.dataForm.personReword.reward) {
            let reward_url = await this.uploadContent(
              "reward",
              this.dataForm.personReword.reward
            );
            if (!reward_url || !reward_url.length) {
              this.$message.error("保存失败, 上传个人奖励失败");
              return;
            }
            console.log("个人奖励url", d.personReword.reward_url);
            d.personReword.reward_url = reward_url;
          }
          // 门派奖励
          if (this.org_rule != this.dataForm.teamReward.reward) {
            let reward_url = await this.uploadContent(
              "rule",
              this.dataForm.teamReward.reward
            );
            if (!reward_url || !reward_url.length) {
              this.$message.error("保存失败, 上传门派奖励失败");
              return;
            }

            d.teamReward.reward_url = reward_url;
          }

          if (this.org_score != this.dataForm.scoreRule.rule) {
            let rule_url = await this.uploadContent(
              "score",
              this.dataForm.scoreRule.score
            );
            if (!rule_url || !rule_url.length) {
              this.$message.error("保存失败, 上传积分规则失败");
              return;
            }

            d.scoreRule.rule_url = rule_url;
          }

          d.match_name = this.optionsMatch[d.match_type];
          let { data, errorCode } = await this.$http.match_subject.save_subject(
            d
          );
          if (errorCode != "0000") {
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.removeTabByPath(`/match/subject/match_list`);
          this.$router.push(`/match/subject/match_list`);
          this.$bus.$emit("onMatchChanged", data);
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "desc") {
              id1 = "quillEditorQiniu";
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
      };

      console.log(data);
      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },

    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.kvPicture = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },

    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },

    async httpUploadEditorRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功0");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async httpUploadEditorRequest1(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功1");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditorPrize.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async httpUploadEditorRequest2(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功1");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditormy.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },
    async uploadContent(t, d) {
      let blob = new Blob([d], { type: "text/html;charset=utf-8" });

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: "utf-8",
        });
      };

      let fileName = t + "_" + randomStr() + ".html";
      let textContain = transToFile(blob, fileName, "application/json");

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res;
            let path = uploadOssUrl().richText + this.type + "/" + fileName;
            let result = await this.$http.uploadFile(path, fileName, file);
            resolve(result);
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
    cancelDialog() {},
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>
<style src="../../editor.css"></style>
<style scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}
.TotalAmount {
  width: 180px;
}

::v-deep.editor .ql-editor {
  height: 667px;
}

/*::v-deep .el-form-item__label {*/
/*  width: 150px!important;*/
/*}*/

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
::v-deep .el-upload-dragger {
  width: 178px;
  height: 178px;
}
::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .el-upload-dragger {
  text-align: center;
}

::v-deep .el-upload-dragger img {
  margin: auto;
}

::v-deep #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

::v-deep #quillEditorQiniuPrize .el-form-item__content {
  height: 100%;
}

::v-deep #quillEditorQiniugeren .el-form-item__content {
  height: 100%;
}

::v-deep .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

::v-deep .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
</style>
